<template>
  <div class="d-flex justify-content-between flex-wrap">
    <div class="d-flex align-items-center mb-0 mt-1">
      <span class="text-nowrap"> Total de {{ value.total }} itens </span>
    </div>
    <div>
      <b-pagination
        :value="1"
        :total-rows="value.total"
        :per-page="perPage"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="mt-1 mb-0"
        @input="(valor)=>value.pageChanged({currentPage:valor})"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    value: {
      required: true,
    },
    perPage: {
      type: Number,
      default: 20,
    },
  },
}
</script>
